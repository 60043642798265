<template>
  <div class="graph-circle">
    <div class="circle" :class="{incharge: selected === 1, available: selected === 0, outoforder: selected === 2}">
      <highcharts
          class="chart"
          :options="pieOptions"
      />
      <div class="circle-inside">
        <v-icon>ico-charging-point</v-icon>
        <div>
          <number
              class="animated-number"
              ref="number"
              :from="0"
              :to="nbBornes"
              :duration=".6"
              easing="Power4.easeOut"

          />
        </div>
        <span :title="$t('charging-point.charging-point')">{{ $t('circle-graph.evse') }}</span>
      </div>
    </div>

    <div class="filters">
      <div>
        <v-chip outlined link class="f-all" :class="{active: selected === null}" @click="selected=null">{{ $t('circle-graph.total') }}</v-chip>
      </div>
      <div>
        <v-chip outlined link class="f-incharge" :class="{active: selected === 1}" @click="selected=1">{{ $t('circle-graph.in-charge') }}</v-chip>
      </div>
      <div>
        <v-chip outlined link class="f-available" :class="{active: selected === 0}" @click="selected=0">{{ $t('circle-graph.available') }}</v-chip>
      </div>
      <div>
        <v-chip outlined link class="f-outoforder" :class="{active: selected === 2}" @click="selected=2">{{ $t('circle-graph.offline') }}</v-chip>
      </div>
    </div>
  </div>
</template>

<script>
import ChargingPointRepository from "@repository/ChargingPointRepository";

export default {

  data() {
    return {
      selected: null,
      statusCounter: [],
    }
  },


  mounted() {
    this.loadData()
  },

  computed: {

    nbBornes() {
      return this.selected === null ? this.total : this.values[this.selected];
    },

    total() {
      return this.values[0] + this.values[1] + this.values[2]
    },

    values() {

      let free = 0;
      let busy = 0;
      let hs = 0;

      for(let i = 0; i < this.statusCounter.length; i++) {
        let s = this.statusCounter[i]
        if(s.status === 'AVAILABLE') {
          free += s.count
        } else if(s.status === 'OFFLINE' || s.status === 'OUTOFORDER') {
          hs += s.count
        } else if(s.status === 'CHARGING' || s.status === 'BLOCKED') {
          busy += s.count
        }
      }


      return [free, busy, hs]
    },

    pieOptions() {


      let values = this.values
      let total = this.total

      let restePercents = [
        (100 - (values[0] * 100 / total)) * total * 0.01,
        (100 - (values[1] * 100 / total)) * total * 0.01,
        (100 - (values[2] * 100 / total)) * total * 0.01,
      ]

      let colors = [
        '#02897e',
        '#e3b745',
        '#eb4929',
      ]


      let data = []
      data.push({id: 'empty', name: '', y: this.selected == null ? 0 : restePercents[this.selected], color: 'transparent', sliced: true})
      if(values[0] > 0)
        data.push({id: 'free', name: '', y: this.selected === null || this.selected === 0 ? values[0] : 0, color: colors[this.selected !== null ? this.selected : 0], sliced: true})
      if(values[1] > 0)
        data.push({id: 'busy', name: '', y: this.selected === null || this.selected === 1 ? values[1] : 0, color: colors[this.selected !== null ? this.selected : 1], sliced: true})
      if(values[2] > 0)
       data.push({id: 'hs', name: '', y: this.selected === null || this.selected === 2 ? values[2] : 0, color: colors[this.selected !== null ? this.selected : 2], sliced: true})

      let $this = this

      return {
        accessibility: {
          enabled: false,
        },
        chart: {
          type: 'pie',
          backgroundColor: 'transparent',
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          margin: [0,0,0,0],
          width: 200,
          height: 200,
          spacingTop: 0,
          spacingBottom: 0,
          spacingLeft: 0,
          spacingRight: 0,
        },
        legend: {
          enabled: false,
        },
        credits: false,
        title:{
          text:'',
          margin: 0,
        },
        subTitle:{
          text:'',
          margin: 0,
        },
        plotOptions: {
          pie: {

            innerSize: '96%',
            size: 175,
            borderWidth: 25,
            borderColor: null,
            slicedOffset: 0,
            dataLabels: {
              connectorWidth: 0,
              enabled: false
            },
            showInLegend: true,
          }
        },
        tooltip: {
          enabled: false,
        },
        series: [{
          data,
          showInLegend: false,
          states: {
            inactive: {
              opacity: 1
            },
          },
          cursor: 'pointer',
          point: {
            events: {
              click: function() {
                if(this.id === 'free') {
                  if($this.selected === null) {
                    $this.selected = 0
                  } else {
                    $this.selected = null
                  }
                }
                if(this.id === 'busy') {
                  if($this.selected === null) {
                    $this.selected = 1
                  } else {
                    $this.selected = null
                  }
                }
                if(this.id === 'hs') {
                  if($this.selected === null) {
                    $this.selected = 2
                  } else {
                    $this.selected = null
                  }
                }
              }
            }
          }
        }]
      }
    },

  },

  methods: {

    loadData() {
      ChargingPointRepository.statusCounter().then(response => {
        this.statusCounter = response
      }).catch(e => {
        console.error(e)
      })
    },

  }
}
</script>

<style lang="scss">
  .graph-circle {
    align-items: center;
    display: flex;
    flex-direction: column;
    .circle {
      width: 220px;
      height: 220px;
      border-radius: 50%;
      box-shadow:
          inset 5px 5px 5px #00000029,
            //inset 5px -6px 10px 0px #00000029,
            //inset -11px 10px 10px 0px #00000029,
          -5px -5px 5px #00000029,
          4px -3px 5px #00000029,
          5px 5px 5px #ffffff,
          0px 0px 10px #ffffff
    ;
      background-color: #f9f9f9;
      background-repeat: no-repeat;
      background-position: 10px 10px;
      background-size: 91%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #758093;
      &.outoforder {
        color: #E53C22;
      }
      &.available {
        color: #00897E;
      }
      &.incharge {
        color: #DEAC3C;
      }
      .chart {
        width: 200px;
        height: 200px;
        border-radius: 50%;
      }
      .circle-inside {

        position: absolute;
        font-size: 22px;
        font-weight: bold;
        line-height: 1;
        text-align: center;
        width: 139px;
        height: 139px;
        background: #EFEFEF;
        display: flex;

        border-radius: 50%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .v-icon {
          font-size: 40px;
          color: inherit;
        }
        .animated-number {
          transition: all 0.3s ease-in;
          font-weight: 600;
          font-size: 40px;
        }
      }
    }

    .filters {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 0 50px;
      position: relative;
      div {
        width: 50%;
        display: flex;
        justify-content: center;
        .v-chip {
          width: 90%;
          height: 40px;
          border-radius: 25px;
          display: block;
          margin-top: 20px;
          text-align: center;
          z-index: 1;
          transition: all .2s ease-in;
          .v-chip__content {
            font-size: 22px;
            font-weight: bold;
          }
          &.f-all {
            color: #758093;
            border-color: #758093;
            &.active {
              background: #758093 !important;
            }
          }
          &.f-outoforder {
            color: #E53C22;
            border-color: #E53C22;
            &.active {
              background: #E53C22 !important;
            }
          }
          &.f-available {
            color: #00897E;
            border-color: #00897E;
            &.active {
              background: #00897E !important;
            }
          }
          &.f-incharge {
            color: #DEAC3C;
            border-color: #DEAC3C;
            &.active {
              background: #DEAC3C !important;
            }
          }

          &.active {
            color: #fff;
            border-color: #fff;
          }
        }
      }
    }
  }

</style>